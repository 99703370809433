.headerTitleWhite {
    color      : #FFF;
    font-size  : 49px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 50px;
}

.newIntegration {
    padding-top: 50px;
}

.seamless {
    background             : linear-gradient(100deg, rgba(3, 65, 161, 0.78) -12.68%, #22B7BB 35.79%);
    background-clip        : text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size  : 32px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
}

.crypto {
    color      : #000;
    font-size  : 32px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
}

.subtitleIntegrate {
    color      : var(--Black, #202020);
    font-size  : 21px;
    font-style : normal;
    font-weight: 500;
    line-height: 29.6px;
}

.colTitle {
    color: var(--Black, #202020);

    /* H6 */
    font-family: 'Roboto', system-ui;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.6px; /* 140.952% */
    /* 140.952% */
}

.linkdetailCol {
    color: var(--Grey, #9E9E9E);

    /* Body */
    font-family: 'Roboto', system-ui;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.1px; /* 133.889% */
}

.colData {
    /*max-width  : 280px;*/
    min-height : 169px;
    border     : 1px solid var(--Medium-grey, rgba(222, 222, 222, 0.80));
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .colData {
        min-width: 170px;
    }
}

.branch-image {
    width: 100px;
    object-fit: contain;
}

.branch-top-content {
    height: 100px;
    display: flex;
    align-items: center;
}

.branch-content {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 0 !important;
}

.sub-branch-content {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 576px) {
    .label-col {
        margin-bottom: 1rem;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .label-col {
        margin-bottom: 1rem;
    }
}

@media (max-width: 399px) {
    .col-stack-400 {
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }
}
