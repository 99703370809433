/* team page css */
.teamHeader {
  padding-top: 130px;
}
.containAll{
  position: relative;
  text-align: left;
}

.meetteam {
  text-align: center;
  background: var(--Text-gradient,
      linear-gradient(103deg, rgba(3, 65, 161, 0.78) -12.23%, #22b7bb 99.35%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 80px;
}
.meetteam_2 {
  text-align: center;
  background: var(--Text-gradient,
      linear-gradient(103deg, rgba(3, 65, 161, 0.78) -12.23%, #22b7bb 99.35%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 30px;
  padding-bottom: 30px;
}

.teamHeader-card {
  padding: 30px 0;
}

.team-container {
  margin-top: -20px;
}

.teamTitle {
  color: #707070;
  font-size: 18px;
}

.teamDes {
  font-size: 12px;
  overflow: scroll;
  height: 400px;
}

.teamDes::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.team-page-title {
  margin-top: -70px;
  padding-bottom: 50px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  background: linear-gradient(100.48deg, rgba(3, 65, 161, 0.784314) -12.68%, #22B7BB 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.team-page-title_2 {
  padding-top: 120px;
  padding-bottom: 60px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  background: linear-gradient(100.48deg, rgba(3, 65, 161, 0.784314) -12.68%, #22B7BB 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.team-label {
  color: #fff;
  font-weight: 600;
  font-size: 25px;
}

.team-member {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
}

.title-overlay {
  position: absolute;
  top: 55%;
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s;
}

.title-overlay-with-role {
  position: absolute;
  top: 45%;
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s;
}

.team-member:hover .title-overlay {
  opacity: 1;
}

.team-member:hover .title-overlay-with-role {
  opacity: 1;
}

.team-name {
  cursor: pointer;
  color: #2c729a;
  font-weight: 700;
  font-size: 15px;
  text-decoration: underline;
  white-space: nowrap;
}

.team-title {
  color: #2c729a;
  font-weight: 500;
  font-size: 9px;
}

.team-role {
  color: #2c729a;
  font-weight: 700;
  font-size: 9px;
  font-style: italic;
}

.team-member:hover img {
  background-color: white;
  mix-blend-mode: screen;
  opacity: 0.7;
}

/* Flip card */
.flip-box {
  background-color: transparent;
  min-height:  470px;
  /* height: 470px;
  perspective: 1000px; */
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
}

.flip-box-front {
  text-align: center;
}

.flip-box-back {
  transform: rotateY(180deg);
}

.container-open{
  display: flex;
  justify-content: flex-end;
  padding: 14px;
}

.img-hover-effect {
  transition: opacity 0.3s ease, background 0.3s ease, mix-blend-mode 0.3s ease;
}

.img-hover-effect:hover {
  opacity: 0.5;
  background: var(--Dark-Navy, #031A3A);

}

.containerAll {
  position: relative;
  text-align: center;
  color: white;
}
/* Team landing page css section */
.bg-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 2500px;
  width: 1100px;
}

.landingPageContainer {
  padding-top: 200px;

  overflow-y: overlay;
  overflow-x: hidden;
  background-color: #F8F9FA;
}
.image-container {
  position: relative;
  overflow: hidden;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--Dark-Navy, #031A3A);
  opacity: 0;
  mix-blend-mode: multiply;
}

.image-container:hover .overlay {
  opacity: 0.5;
}

.landingPageContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.container-name{
  padding: 28px;
}
.nameCard{
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.1px; 
  padding-bottom: 5px;
}

.titleCard{
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.1px; /* 133.889% */
  text-align: left;
}

/* Responsive */
@media screen and (max-width: 1200px)  {
  .landingPageContainer {
    overflow-x: overlay;
  }
  .flip-box {
    min-height: 320px;
    perspective: 1000px; 
  }
}
@media screen and (max-width: 1700px)  {
  .landingPageContainer {
    overflow-x: overlay;
  }
  .flip-box {
    min-height: 400px;
    perspective: 1000px; 
  }
}




/* Team Avatar CSS */
/* Management & Operation */
.team-circle {
  position: relative;
  bottom: 150px;
  left: 80px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.jakub {
  position: relative;
  border-radius: 50%;
  bottom: 440px;
  left: 290px;
  border: none;
  width: 200px;
  height: 200px;
}

.Matthew {
  position: relative;
  border-radius: 50%;
  bottom: 493px;
  left: 445px;
  border: none;
  width: 146px;
  height: 146px;
}

.Pamela {
  position: relative;
  border-radius: 50%;
  bottom: 780px;
  left: 670px;
  border: none;
  width: 130px;
  height: 130px;
}

.Linh {
  position: relative;
  bottom: 790px;
  left: 593px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: none;
}

/* Marketing & Design */
.team-circle-marketing {
  position: relative;
  bottom: 1109px;
  left: 835px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.Leanne {
  position: relative;
  border-radius: 50%;
  bottom: 710px;
  left: 626px;
  border: none;
  width: 13rem;
  height: 13rem;
}

.Serin {
  position: relative;
  border-radius: 50%;
  bottom: 735px;
  left: 740px;
  width: 11rem;
  height: 11rem;
  border: none;
}

.Peter {
  position: relative;
  border-radius: 50%;
  bottom: 708px;
  left: 575px;
  width: 10rem;
  height: 10rem;
  border: none;
}

.Dima {
  position: relative;
  bottom: 998px;
  left: 520px;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  border: none;
}

/* Tech Team */
.team-circle-tech {
  position: relative;
  bottom: 200px;
  left: 500px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.FDS {
  position: relative;
  border-radius: 50%;
  bottom: 400px;
  left: 740px;
  border: none;
  width: 170px;
  height: 170px;
}

.techTeam {
  position: relative;
  border-radius: 50%;
  bottom: 415px;
  left: 658px;
  border: none;
  width: 186px;
  height: 186px;
}

.Abhisaran {
  position: relative;
  border-radius: 50%;
  bottom: 1070px;
  left: 535px;
  border: none;
  width: 12rem;
  height: 12rem;
}

/* Advisor */
.team-circle-advisor {
  position: relative;
  bottom: 2180px;
  left: 372px;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(#266a92, #71a5c3);
  border: none;
}

.Krzysztof {
  position: relative;
  border-radius: 50%;
  bottom: 1272px;
  left: 210px;
  border: none;
  width: 200px;
  height: 200px;
}

.Rutger {
  position: relative;
  border-radius: 50%;
  bottom: 1673px;
  left: 195px;
  border: none;
  width: 190px;
  height: 190px;
}

.Jonathan {
  position: relative;
  border-radius: 50%;
  bottom: 1813px;
  left: 2px;
  border: none;
  width: 190px;
  height: 190px;
  object-fit: fill;
  background-color: #e1ecf9;
}

.Dan {
  position: relative;
  border-radius: 50%;
  bottom: 1360px;
  left: 500px;
  border: none;
  width: 10rem;
  height: 10rem;
}

.John {
  position: relative;
  border-radius: 50%;
  bottom: 1360px;
  left: 530px;
  border: none;
  width: 8rem;
  height: 8rem;
}

.Anouk {
  position: relative;
  border-radius: 50%;
  bottom: 1093px;
  left: 490px;
  border: none;
  width: 178px;
  height: 178px;
}

.Rex {
  position: relative;
  border-radius: 50%;
  bottom: 1045px;
  left: 283px;
  border: none;
  width: 178px;
  height: 178px;
}

.Jason {
  position: relative;
  border-radius: 50%;
  bottom: 1806px;
  left: 37px;
  border: none;
  width: 178px;
  height: 178px;
}

.card-team {
  min-width: 320px;
}

.card-body {
  border: 1px solid #eaecf0;
  background: #fff;
}

.cardTitle {
  color: #282828;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 29.6px; 
  /* 140.952% */
}

.cardText {
  color: #3e3e59;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.brightness{
  filter:brightness(0.5) !important;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 0px;
}

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}