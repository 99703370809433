
.markdown-content {
    font-family: 'Roboto', sans-serif;
    line-height: 1.7;
    color: #333;
    padding: 20px;

    height: max-content; /* or a specific height */
    overflow-y: auto; /* Make sure content is scrollable */
}

.markdown-content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* Headings */
.markdown-content h1 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5rem;
}

.markdown-content h2 {
    font-size: 1.75rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
}

.markdown-content h3 {
    font-size: 1.5rem;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
}

/* Paragraphs */
.markdown-content p {
    margin-bottom: 0;
    font-size: 1rem;
}

/* Links */
.markdown-content a {
    color: #2f80ed;
    text-decoration: none;
}

.markdown-content a:hover {
    text-decoration: underline;
}

/* Code Blocks */
.markdown-content pre, .markdown-content code {
    font-family: 'Source Code Pro', monospace;
    background-color: #f6f8fa;
    padding: 10px;
    border-radius: 5px;
    display: block;
    margin-bottom: 1.25rem;
    font-size: 0.95rem;
}

/* Blockquotes */
.markdown-content blockquote {
    border-left: 4px solid #ddd;
    padding-left: 1rem;
    color: #555;
    margin-bottom: 1.25rem;
}

/* Images */
.markdown-content img {
    max-width: 50%;
    width: auto;
    height: auto;
    border-radius: 5px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


/* Mobile Devices */
@media (max-width: 576px) { /* Mobile devices (phones) */
    .markdown-content img {
        max-width: 100%; /* Full width on smaller screens */
    }
}

/* Tablets */
@media (min-width: 577px) and (max-width: 768px) { /* Tablets */
    .markdown-content img {
        max-width: 75%; /* Adjust width for tablets */
    }
}

/* Larger Devices */
@media (min-width: 769px) { /* Desktops and larger devices */
    .markdown-content img {
        max-width: 50%; /* Default width for larger screens */
    }
}

/* Tables */
.markdown-content table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

.markdown-content table th, .markdown-content table td {
    border: 1px solid #ddd;
    padding: 10px;
}

.markdown-content table th {
    background-color: #f6f8fa;
    font-weight: bold;
}

/* Horizontal Rule */
.markdown-content hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 2rem 0;
}


/* Lists */
.markdown-content ul, .markdown-content ol {
    padding-left: 1.5rem; /* Indent lists */
    margin: 0; /* Reset default margin */
    /*line-height: 1.6; !* Improve readability *!*/
}

.markdown-content ul {
    list-style-type: disc; /* Default disc for unordered lists */
}

.markdown-content ol {
    list-style-type: decimal; /* Default decimal for ordered lists */
    margin: 0 0 2rem 0;
}

.markdown-content h4 {
    margin: 2rem 0 0 0;
}

.markdown-content li {
    height: auto;
    margin-bottom: 0; /* Space between list items */
    text-align: left; /* Ensure text is left-aligned */
}

/* Optional: Adjust nested lists for better readability */
.markdown-content ul ul, .markdown-content ol ol {
    padding-left: 2rem; /* Indent nested lists */
}
