.container-header {
  overflow: hidden;
}

.spaceHeight {
  height: 100px;
}

.start_journaler_bg {
  background    : #000;
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.bgIntegration {
  background: rgba(65, 161, 200, 0.1);
}

.journalerMainHeader {
  width           : 100%;
  height          : 100%;
  background-image: url("../../../assets/journaler/main/bg.png");
  overflow        : hidden;
  background-size : cover;
}

.titleJournaler {
  text-align : left;
  color      : var(--White, #fff);
  font-size  : 40px;
  font-style : normal;
  font-weight: 700;
  line-height: 56.9px;
  /* 142.25% */
}

.subJournaler {
  text-align : left;
  color      : var(--White, #fff);
  font-size  : 24px;
  font-style : normal;
  font-weight: 500;
  line-height: 33.1px;
  /* 137.917% */
}

.titleHeader {
  color: var(--Black, #202020);

  font-size  : 40px;
  font-style : normal;
  font-weight: 700;
  line-height: 56.9px;
}

.firstHeader {
  color      : var(--Grey, #9e9e9e);
  font-size  : 18px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.img-main {
  /* height : 480px; */
  padding-top   : 100px;
  padding-bottom: 50px;
}

.btnContainer {
  padding-bottom: 280px;
}

.titleFeature {
  text-align: left;
  font-size : 20px;
  color     : #000000;
}

.contentFeature {
  color: var(--Grey, #9e9e9e);

  font-size  : 21px;
  font-style : normal;
  font-weight: 500;
  line-height: 29.6px;
  /* 140.952% */
}

.solutionJounaler {
  color      : #000;
  text-align : center;
  font-size  : 40px;
  font-style : normal;
  font-weight: 700;
  line-height: 56.9px;
  /* 142.25% */
}

.subTitlejournaler {
  color: var(--Black, #202020);

  font-size  : 21px;
  font-style : normal;
  font-weight: 500;
  line-height: 29.6px;
  /* 140.952% */
}

.solutionJounalerGradient {
  background: linear-gradient(103deg,
      rgba(3, 65, 161, 0.78) -12.23%,
      #22b7bb 99.35%);
  background-clip        : text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleCard {
  color     : #000;
  text-align: center;

  font-size  : 28px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.subTitleCard {
  color     : var(--Grey, #9e9e9e);
  text-align: center;

  font-size  : 24px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.solutionJounalerSub {
  color     : var(--Grey, #9e9e9e);
  text-align: center;

  font-size  : 24px;
  font-style : normal;
  font-weight: 500;
  line-height: 33.1px;
  /* 137.917% */
}

.journalerDemoWrapper {
  display        : flex;
  justify-content: center;
  align-items    : center;
  box-sizing     : border-box;
  z-index        : 2;
  margin-top     : -250px;
  margin-bottom  : -200px;
  /* margin-top  : 50px; */
}

.journalerFeatureContainer {
  /* padding-top: 200px; */
}

.testJournalerFree {
  background-color: #222222;
  background-image: url(../../../assets/pricing/header_bg.png);
  color           : white;
  padding         : 60px 0 50px 0;
}

.startJournalerBtn {
  padding         : 15px 10px;
  background-color: #ffffff;
  border-radius   : 30px;
  font-weight     : bold;
  width           : 256px;
}

.integrationCard {
  border       : 0;
  box-shadow   : 0 3px 6px #00000029;
  border-radius: 10px;
}

.start_journaler {
  color     : #fff;
  text-align: center;

  font-size  : 49px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.titleOurFeatures {
  font-size  : 39px;
  font-weight: bold;
}

/* ------- Two buttons: -------- */
.whiteBgBtnOne {
  padding         : 15px 10px;
  background-color: #094674;
  color           : #ffffff;
  border-radius   : 30px;
  font-weight     : bold;
  margin-right    : 30px;
  width           : 256px;
}

.startLink {
  color          : #ffffff;
  text-decoration: none;
}

.startLink:hover {
  color          : #ffffff;
  text-decoration: none;
}

.whiteBgBtnTwo {
  border-radius: 30px;
  border       : 2px solid #094674;
  font-weight  : bold;
  width        : 256px;
}

.whiteBgBtnTwoText {
  color          : #094674;
  text-decoration: none;
}

.whiteBgBtnTwoText:hover {
  color          : #094674;
  text-decoration: none;
}

.mainJounaler {
  font-size: 50px;
  color    : white;
}

.integratedPlatforms {
  display          : flex;
  justify-content  : center;
  align-items      : center;
  height           : 130px;
  width            : 130px;
  /* mix-blend-mode: luminosity; */
}

.logoImage {
  width     : 130px;
  height    : 130px;
  object-fit: cover;
}

.logoIntegration {
  width     : 130px;
  height    : 130px;
  object-fit: contain;
}

.logoImageOur {
  width       : 150px;
  height      : 150px;
  max-width   : 100%;
  max-height  : 100%;
  object-fit  : cover;
  transform   : scale(1, 1);
  transition  : transform 0.5s ease;
  margin-right: 25px;
  margin-left : 25px;
}

.logoImageOur:hover {
  transform: scale(1.3, 1.3);
}

.logoImageXero {
  max-width : 150px;
  width     : 80px;
  max-height: 120%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .demoWrapper {
    margin-top: -180px;
  }

  .img {
    width: 100%;
  }

  .journalerDemoWrapper {
    margin-bottom: 0;
  }

  .journalerFeatureContainer {
    padding-top: 40px;
  }
}

/* new feature section */
.featureDetail {
  padding: 30px;
}

.cryptoName {
  font-size: 14px;
}

.selectedFeature {
  display        : flex;
  justify-content: center;
  align-items    : center;
  border-radius  : 4px;
  height         : 50px;
  background     : #0b4875;
  color          : #fff;
  text-align     : center;

  font-size  : 18px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.unselectedFeature {
  font-size: 20px;
  color    : #fff;
  padding  : 5px 10px;
}

.whiteBorderBottom {
  display        : flex;
  justify-content: center;
  align-items    : center;
  border-radius  : 4px;
  border         : 2px solid #0b4875;
  background     : #fff;
  height         : 50px;

  color      : #0b4875;
  text-align : center;
  font-size  : 18px;
  font-style : normal;
  font-weight: 700;
  line-height: normal;
}

.selectedFeature:hover {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .mainJounaler {
    font-size: 20px;
  }

  .btnGroup_whiteBgBtn {
    width          : 60%;
    font-size      : 12px;
    white-space    : nowrap;
    overflow       : hidden;
    text-overflow  : ellipsis;
    margin-bottom  : 20px;
    /* margin-right: 20px; */
  }

  .btnGroup_whiteBorderBtn {
    width        : 60%;
    font-size    : 12px;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
  }

  .solutionJounaler {
    font-size: 14px;
  }

  .journalerMainHeader {
    overflow: hidden;
    height  : fit-content;
  }

  .img-blockchain {
    padding-top: 15%;
  }

  .logoImage {
    width: 150px;
  }

  .titleJournaler {
    font-size  : 32px;
    line-height: normal;
    font-style : normal;
    font-weight: 700;
    text-align : left;
    width      : 80%;
  }

  .subJournaler {
    font-size  : 21px;
    text-align : center;
    line-height: normal;
    font-style : normal;
    font-weight: 700;
    text-align : left;
    width      : 80%;
  }

  .solutionJounaler {
    font-size  : 32px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
  }

  .solutionJounalerSub {
    font-size  : 21px;
    font-style : normal;
    font-weight: 500;
    line-height: 29.6px;
  }

  .img-optimis {
    height: 80px;
    width : 80px;
  }

  .titleCard {
    font-size  : 24px;
    font-style : normal;
    font-weight: 500;
  }

  .subTitleCard {
    font-size  : 24px;
    font-style : normal;
    font-weight: 500;
    line-height: normal;
  }

  .spaceHeight {
    height: 15px;
  }

  .titleOurFeatures {
    font-size  : 32px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
  }

  .detailOurFeatures {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .detailOurFeatures::-webkit-scrollbar {
    /* width  : 1px !important;
    height : 1px !important; */
    display: block;
  }

  .detailOurFeatures::-webkit-scrollbar-track {
    background: transparent;
    width     : 1px !important;
  }

  .selectedFeature {
    font-size   : 14px;
    min-width   : 150px;
    margin-right: 10px;
  }

  .whiteBorderBottom {
    font-size   : 14px;
    min-width   : 150px;
    margin-right: 10px;
  }

  .titleHeader {
    font-size  : 32px;
    font-style : normal;
    font-weight: 700;
    line-height: normal;
  }

  .contentFeature {
    padding-left: 25px;
    font-size   : 18px;
    font-style  : normal;
    font-weight : 400;
    line-height : normal;
  }

  .start_journaler {
    width      : 90%;
    font-size  : 40px;
    font-style : normal;
    font-weight: 700;
    line-height: 56.9px;
  }

  .firstHeader {
    padding-top: 15px;
  }

  .titleHeader {
    padding-top: 15px;
  }
}

.logoImageEx {
  max-width : 70%;
  max-height: 70%;
  object-fit: cover;
}
