.header-title {
    margin-left: 1rem !important;
}

.help-center-container {
    padding-top: 100px;
    height: 100vh; /* Ensures the menu is contained within the viewport height */

    overflow-y: auto;
    overflow-x: hidden;
    background-color: #F8F9FA;
}

.help-center-container::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* Responsive */
@media screen and (max-width: 1200px)  {
    .help-center-container {
        overflow-x: auto;
    }
}
@media screen and (max-width: 1700px)  {
    .help-center-container {
        overflow-x: auto;
    }
}

.menu-item {
    border: none;
    background-color: transparent;
    padding: 4px 4px;
    transition: background-color 0.3s ease;

    cursor: pointer;
    align-items: center;
    flex-direction: row;
    display: flex;
}

.menu-item-selected {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.menu-item:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.static-menu {
    position: sticky;
    top: 0;
    max-height: 100vh;
    max-width: 320px !important;
    overflow-y: hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
    scrollbar-width: none;
}

.static-menu::-webkit-scrollbar {
    display: none;
}

.static-menu:hover {
    overflow-y: auto;
}

/* Mobile Devices */
@media (max-width: 576px) { /* Mobile devices (phones) */
    .static-menu {
        display: none;
    }
}

/* Tablets */
@media (min-width: 577px) and (max-width: 768px) { /* Tablets */
    .static-menu {
        display: none;
    }
}

/* Larger Devices */
@media (min-width: 769px) { /* Desktops and larger devices */
    .static-menu {
    }
}


/* Drawer for mobile view */
.static-menu-mobile {
    position: fixed;
    top: 0;
    left: -100%; /* Hidden off-screen */
    height: 100%;
    width: 80%; /* Adjust as necessary */
    background-color: white;
    z-index: 1050; /* Adjust z-index to overlay correctly */
    transition: left 0.3s ease;
    overflow-y: auto;
}

.static-menu-mobile.open {
    left: 0; /* Slide in the menu */
}

/* Overlay when menu is open */
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040; /* Slightly less than menu */
}

.right-content {
    background-color: #f8f9fa;
}

.menu-toggle-btn {
    position: relative;
    z-index: 1020; /* Above overlay */
}

.scroll-to-top-reference {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -100px;
}

.side-button, .side-button:hover, .side-button:active, .side-button:focus {
    color: #24242a !important;
    border-color: transparent;
    border-width: 0 0 0 0;
    text-decoration: none;
    box-shadow: 0 0 0 0 transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Container for responsive iframe */
.video-iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

/* Style the iframe to fill the container */
.video-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* Mobile Devices */
@media (max-width: 576px) { /* Mobile devices (phones) */
    .video-iframe-container {
        max-width: 100%; /* Full width on smaller screens */
    }
}

/* Tablets */
@media (min-width: 577px) and (max-width: 768px) { /* Tablets */
    .video-iframe-container {
        max-width: 75%; /* Adjust width for tablets */
    }
}

/* Larger Devices */
@media (min-width: 769px) { /* Desktops and larger devices */
    .video-iframe-container {
        max-width: 75%; /* Default width for larger screens */
    }
}
